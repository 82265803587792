import React from "react";
import { graphql } from "gatsby";
import Home from "../components/Home";

class HomePage extends React.Component {
  render() {
    const { location, data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const areas = data.areas.edges.map((edge) => edge.node);
    const seasons = data.seasons.edges.map((edge) => edge.node);
    const services = data.services.edges.map((edge) => edge.node);
    const careers = data.careers.edges.map((edge) => edge.node);

    return (
      <Home
        location={location}
        siteTitle={siteTitle}
        areas={areas}
        seasons={seasons}
        services={services}
        careers={careers}
      />
    );
  }
}

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    services: allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { fields: { slug: { glob: "/services/**" } } }
    ) {
      edges {
        node {
          ...MarkdownRemarkData
        }
      }
    }
    seasons: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { glob: "/seasons/**" } } }
    ) {
      edges {
        node {
          ...MarkdownRemarkData
        }
      }
    }
    areas: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { glob: "/areas/**" } } }
    ) {
      edges {
        node {
          ...MarkdownRemarkData
        }
      }
    }
    careers: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { glob: "/careers/**" } } }
    ) {
      edges {
        node {
          ...MarkdownRemarkData
        }
      }
    }
  }

  fragment MarkdownRemarkData on MarkdownRemark {
    excerpt
    fields {
      slug
    }
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      title
      description
      thumbnail {
        publicURL
      }
    }
  }
`;
