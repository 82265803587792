import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import Layout from "../common/Layout";
import SEO from "../common/SEO";
import Button from "../common/Button";
import Container from "../common/Container";
import StaticMap from "../common/StaticMap";
import PrettyList from "../common/PrettyList";
import styles from "./Home.module.scss";
import { CONTACT_URL } from "../../constants/urls";

class Home extends React.Component {
  render() {
    const {
      location,
      siteTitle,
      seasons,
      services,
      areas,
      careers,
    } = this.props;
    console.log(careers);
    return (
      <Layout location={location} title={siteTitle}>
        <SEO
          title="Home"
          description="Front range pruning, tree removal, tree planting, and cabling & support system specialists."
          keywords={[
            ...services.map((node) => node.frontmatter.title),
            ...seasons.map((node) => node.frontmatter.title),
            ...areas.map((node) => node.frontmatter.title.replace(/,/, "")),
          ]}
        />
        <div className={styles.hero}>
          <div className={styles.heroImg} />
          <div className={styles.heroOverlay}>
            <Container>
              <div className={styles.heroContent}>
                <h1 className={styles.heroTitle}>O'Brien Tree Care</h1>
                <p className={styles.heroDescription}>
                  Your local, front range tree care professionals.
                </p>
                <Button className={styles.heroButton} to={CONTACT_URL}>
                  Contact Us
                </Button>
              </div>
            </Container>
          </div>
        </div>
        <PrettyList
          className={styles.services}
          title={<h2 className="sr-only">Services</h2>}
          items={services.map((node) => ({
            title: node.frontmatter.title || node.fields.slug,
            description: node.frontmatter.description || node.excerpt,
            slug: node.fields.slug,
          }))}
        />
        <PrettyList
          className={styles.seasons}
          listClassName={styles.seasonsList}
          title={<h2 className="sr-only">Seasons</h2>}
          columns={2}
          items={seasons.map((node) => ({
            title: node.frontmatter.title || node.fields.slug,
            description: node.frontmatter.description || node.excerpt,
            imgUrl: get(node.frontmatter, "thumbnail.publicURL"),
            slug: node.fields.slug,
          }))}
        />
        <StaticMap latitude={39.755543} longitude={-105.2211} zoom={8} />
        <div className={styles.areas}>
          <PrettyList
            listClassName={styles.areasList}
            columns={2}
            title={<h2 className="sr-only">Areas</h2>}
            items={areas.map((node) => ({
              title: node.frontmatter.title || node.fields.slug,
              // description: node.frontmatter.description || node.excerpt,
              slug: node.fields.slug,
            }))}
          />
          <Container>
            <p className={styles.areasDisclaimer}>
              * If your area is not listed here, give us a call! We may be able
              to accomodate you.
            </p>
          </Container>
        </div>
        <div className={styles.careers}>
          <PrettyList
            className={styles.carersList}
            title={<h2>We are hiring</h2>}
            description={<p>Careers at O'Brien Tree Care.</p>}
            items={careers.map((node) => ({
              title: node.frontmatter.title || node.fields.slug,
              description: node.frontmatter.description || node.excerpt,
              slug: node.fields.slug,
            }))}
          />
        </div>
      </Layout>
    );
  }
}

Home.propTypes = {
  location: PropTypes.object.isRequired,
  siteTitle: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired,
  areas: PropTypes.array.isRequired,
  careers: PropTypes.array.isRequired,
};

export default Home;
