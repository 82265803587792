import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import styles from "./Button.module.scss";

const Button = ({ className, type, children, to, href, disabled, onClick }) => {
  const commonProps = {
    type,
    children,
    to,
    href,
    disabled,
    onClick,
  };

  let Component = "button";

  if (to) {
    Component = Link;
  } else if (href) {
    Component = "a";
  }

  return <Component {...commonProps} className={cx(styles.root, className)} />;
};

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Button;
